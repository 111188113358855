<template>
  <v-btn
    depressed
    tile
    class="mx-2"
    color="success"
    :disabled="disabled"
    :loading="isLoading"
    @click="onClick"
  >
    <v-icon left>{{
      order && order.attributes.status !== 'requested'
        ? 'mdi-check'
        : 'mdi-send-outline'
    }}</v-icon>
    {{
      order && order.attributes.status !== 'requested'
        ? $t('actions.confirmed')
        : $t('actions.confirmShipment')
    }}
  </v-btn>
</template>

<script>
export default {
  data() {
    return {
      isLoading: false
    }
  },
  computed: {
    order() {
      return this.$store.getters['purchaseOrder/order']
    },
    disabled() {
      return !this.order?.id || this.order?.attributes.status !== 'requested'
    }
  },
  methods: {
    async onClick() {
      this.isLoading = true
      const hash = await this.createTransactionHash()
      await this.updatePurchaseOrder(hash)
      this.isLoading = false
    },
    async createTransactionHash() {
      const response = await this.$store.dispatch(
        'blockchain/create',
        this.order
      )
      return response.result
    },
    async updatePurchaseOrder(hash) {
      const order = { ...this.order }
      order.attributes.status = 'pending'
      order.attributes.customAttributes.blockchain = hash
      await this.$store.dispatch('purchaseOrder/update', order)
    }
  }
}
</script>

<style lang="scss" scoped></style>
