<template>
  <v-list-item>
    <v-list-item-icon>
      <v-icon small>mdi-cube-outline</v-icon></v-list-item-icon
    >
    <v-list-item-content>{{
      $t('purchase.requestedVolume')
    }}</v-list-item-content>
    <v-list-item-content class="font-weight-bold"
      >{{ item.attributes.volume }}
      {{
        item.attributes.volume && item.attributes.volumeUnit
      }}</v-list-item-content
    ><v-list-item-action></v-list-item-action>
  </v-list-item>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      required: true
    }
  }
}
</script>
