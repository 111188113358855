<template>
  <base-secondary-table-layout :card-title="$t('item.itemDetails')">
    <template v-slot:button>
      <v-spacer></v-spacer>
      <base-new-outlined-button
        v-if="itemQuotation.data.id"
        class="mx-2"
        :to="{
          name: 'item-resource-new',
          params: { itemQuotationId: itemQuotation.data.id, orderId: order.id }
        }"
        :disabled="disabled"
        >{{ $tc('item.name', 1) }}</base-new-outlined-button
      >
    </template>
    <ItemResourceIndexTable />
  </base-secondary-table-layout>
</template>

<script>
import ItemResourceIndexTable from './ItemResourceIndexTable'
import { mapGetters } from 'vuex'
export default {
  name: 'ItemResourceIndex',
  components: {
    ItemResourceIndexTable
  },
  props: {
    itemQuotationId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      selected: []
    }
  },
  computed: {
    ...mapGetters({
      itemQuotation: 'itemQuotation/itemQuotation',
      order: 'purchaseOrder/order'
    }),
    disabled() {
      return this.order.attributes.status !== 'requested'
    }
  },
  async created() {
    await this.$store.dispatch('itemResource/filter', {
      parentId: this.itemQuotationId
    })
  }
}
</script>
