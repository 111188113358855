<template>
  <div>
    <BaseLayoutTitle :prefix="$t('purchase.shortName')" :title="reference">
      <template v-slot:edit>
        <v-btn
          v-if="isAdmin"
          outlined
          tile
          class="text-capitalize"
          :to="{ path: 'edit' }"
          append
          color="primary"
          ><v-icon left>mdi-clipboard-edit-outline</v-icon
          >{{ $t('actions.edit') }}</v-btn
        ></template
      >
      <template v-slot:selector>
        <StatusSelector
          class="ml-10"
          :status="order.attributes.status"
          :options="options"
          @change="updateStatus"
      /></template>
      <template v-slot:btn> <PurchaseOrderConfirmationButton /></template>
    </BaseLayoutTitle>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import StatusSelector from '@/components/StatusSelector'
import PurchaseOrderConfirmationButton from '@/views/purchase-order/components/PurchaseOrderConfirmationButton'
export default {
  components: {
    StatusSelector,
    PurchaseOrderConfirmationButton
  },
  data() {
    return {
      options: [
        {
          text: this.$t('orderStatus.requested'),
          status: 'requested',
          color: 'grey lighten-5'
        },
        {
          text: this.$t('orderStatus.pending'),
          status: 'pending',
          color: 'amber lighten-5'
        },
        {
          text: this.$t('orderStatus.etd_confirmed'),
          status: 'etd_confirmed',
          color: 'blue lighten-5'
        },
        {
          text: this.$t('orderStatus.eta_confirmed'),
          status: 'eta_confirmed',
          color: 'blue lighten-3'
        },
        {
          text: this.$t('orderStatus.dispatched'),
          status: 'dispatched',
          color: 'green lighten-5'
        },
        {
          text: this.$t('orderStatus.arrived'),
          status: 'arrived',
          color: 'grey lighten-3'
        },
        {
          text: this.$t('orderStatus.processed'),
          status: 'processed',
          color: 'red lighten-5'
        }
      ]
    }
  },
  computed: {
    ...mapGetters({
      isAdmin: 'auth/isAdmin',
      order: 'purchaseOrder/order',
      orderId: 'purchaseOrder/orderId'
    }),
    reference() {
      const { name } = this.order.attributes.customAttributes
      return name
    },
    invoiceNumber() {
      const { invoiceNumber } = this.order.attributes.customAttributes
      return invoiceNumber
    }
  },
  methods: {
    ...mapActions({ updatePurchaseOrder: 'purchaseOrder/update' }),
    async updateStatus({ status }) {
      const order = {
        ...this.order,
        ...{ attributes: { status } }
      }
      return await this.updatePurchaseOrder(order)
    }
  }
}
</script>
