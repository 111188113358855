<template>
  <v-list-item>
    <v-list-item-icon> <v-icon small>mdi-weight</v-icon></v-list-item-icon>
    <v-list-item-content>{{ $t('purchase.metricTons') }}</v-list-item-content>
    <v-list-item-content class="font-weight-bold"
      >{{ order.attributes.customAttributes.metricTons || '-' }}
    </v-list-item-content>
    <v-list-item-action>
      <v-dialog v-model="dialog" width="500">
        <template v-slot:activator="{ on, attrs }">
          <v-btn small icon v-bind="attrs" color="warning" v-on="on">
            <v-icon small>mdi-pencil</v-icon>
          </v-btn>
        </template>

        <v-card :loading="isLoading">
          <v-card-title> </v-card-title>
          <v-card-text>
            <v-row dense>
              <v-col cols="12">
                <BaseFieldInput
                  v-model="metricTons"
                  type="number"
                  step="0.001"
                  :label="$t('purchase.metricTons')"
                  placeholder="0,000"/></v-col
            ></v-row>
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn light text @click="onReset">
              {{ $t('actions.cancel') }}
            </v-btn>
            <v-btn color="primary" text @click="onClick">
              {{ $t('actions.save') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-list-item-action>
  </v-list-item>
</template>

<script>
import { mapActions } from 'vuex'
export default {
  props: {
    order: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      dialog: false,
      isLoading: false,
      metricTons: null
    }
  },

  watch: {
    dialog: {
      immediate: true,
      handler(isOpen) {
        if (isOpen)
          this.metricTons = this.order.attributes.customAttributes?.metricTons
      }
    }
  },
  methods: {
    ...mapActions({ updatePurchaseOrder: 'purchaseOrder/update' }),
    async onClick() {
      this.isLoading = true
      const order = { ...this.order }
      Object.assign(order.attributes.customAttributes, {
        metricTons: this.metricTons
      })
      await this.updatePurchaseOrder(this.order)
      this.onReset()
    },
    onReset() {
      this.metricTons = null
      this.isLoading = false
      this.dialog = false
    }
  }
}
</script>

<style lang="scss" scoped></style>
