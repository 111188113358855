<template>
  <v-list-item>
    <v-list-item-icon> <v-icon small>mdi-check</v-icon></v-list-item-icon>
    <v-list-item-content>{{
      $t('purchase.shippedVolume')
    }}</v-list-item-content>
    <v-list-item-content class="font-weight-bold"
      >{{
        (order.attributes.customAttributes.shippedVolume &&
          parseFloat(order.attributes.customAttributes.shippedVolume)) ||
          calculatedTotalVolume
      }}

      {{ order.attributes.customAttributes.shippedVolumeUnit }}
    </v-list-item-content>
    <v-list-item-action>
      <v-dialog v-model="dialog" width="500">
        <template v-slot:activator="{ on, attrs }">
          <v-btn small icon v-bind="attrs" color="warning" v-on="on">
            <v-icon small>mdi-pencil</v-icon>
          </v-btn>
        </template>

        <v-card :loading="isLoading">
          <v-card-title> </v-card-title>
          <v-card-text>
            <v-row dense>
              <v-col cols="12" sm="8" md="6">
                <BaseFieldInput
                  v-model="shippedVolume"
                  type="number"
                  step="0.01"
                  :label="$t('item.volume')"
                  placeholder="0,00"
                />
              </v-col>
              <v-col>
                <BaseUnitsSelect
                  v-model="shippedVolumeUnit"
                  name="volume"
                  :filters="['CM', 'MM', 'CORD', 'BF']"
              /></v-col>
            </v-row>
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn light text @click="onReset">
              {{ $t('actions.cancel') }}
            </v-btn>
            <v-btn color="primary" text @click="onClick">
              {{ $t('actions.save') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-list-item-action>
  </v-list-item>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { UnitsConversion } from '@/mixins/units.conversion.mixin'
export default {
  mixins: [UnitsConversion],
  props: {
    order: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      dialog: false,
      isLoading: false,
      shippedVolume: null,
      shippedVolumeUnit: 'm³'
    }
  },
  computed: {
    ...mapGetters({ itemResources: 'itemResource/itemResources' }),
    calculatedTotalVolume() {
      if (!this.itemResources.length) return 0
      const itemsArray = [...this.itemResources].map(
        i => parseFloat(i.attributes.volume || 0) * i.attributes.itemUnitsCount
      )
      const sum = itemsArray.reduce((acc, currentValue) => acc + currentValue)
      return this.roundNumber(sum)
    }
  },

  watch: {
    dialog: {
      immediate: true,
      handler(isOpen) {
        if (isOpen) this.fetchData()
      }
    }
  },

  methods: {
    ...mapActions({
      updatePurchaseOrder: 'purchaseOrder/update'
    }),
    async fetchData() {
      const {
        shippedVolume,
        shippedVolumeUnit
      } = this.order.attributes.customAttributes
      this.shippedVolume = shippedVolume
      this.shippedVolumeUnit = shippedVolumeUnit
    },
    async onClick() {
      this.isLoading = true
      const order = { ...this.order }
      Object.assign(order.attributes.customAttributes, {
        shippedVolume: this.shippedVolume,
        shippedVolumeUnit: this.shippedVolumeUnit
      })
      await this.updatePurchaseOrder(this.order)
      this.onReset()
    },
    onReset() {
      this.shippedVolume = null
      this.shippedVolumeUnit = 'm³'
      this.isLoading = false
      this.dialog = false
    }
  }
}
</script>

<style lang="scss" scoped></style>
