<template>
  <v-list-item>
    <v-list-item-icon>
      <v-icon small>mdi-clock-outline</v-icon></v-list-item-icon
    >
    <v-list-item-content>{{ $t('load.eta') }}</v-list-item-content>
    <v-list-item-content v-if="destination" class="font-weight-bold">{{
      destination.attributes.estimatedUnloadDateTo | moment('lll')
    }}</v-list-item-content
    ><v-list-item-action></v-list-item-action
  ></v-list-item>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  computed: {
    ...mapGetters({
      destination: 'load/destination'
    })
  }
}
</script>
