<template>
  <v-card flat tile
    ><v-card-title class="text-h6"
      ><v-icon left>mdi-newspaper-variant</v-icon>{{ $t('common.details') }}
      <!-- <v-spacer></v-spacer> <PurchaseOrderConfirmationButton
    /> -->
    </v-card-title>
    <v-divider class="mb-8"></v-divider>
    <v-list dense
      ><v-list-item v-if="meta">
        <v-list-item-icon> <v-icon small>mdi-receipt</v-icon></v-list-item-icon>
        <v-list-item-content>{{
          $t('purchase.invoiceNumber')
        }}</v-list-item-content>
        <v-list-item-content class="font-weight-bold">{{
          meta.invoiceNumber || '-'
        }}</v-list-item-content
        ><v-list-item-action></v-list-item-action
      ></v-list-item>
      <v-list-item v-if="meta">
        <v-list-item-icon>
          <v-icon small>mdi-folder-outline</v-icon></v-list-item-icon
        >
        <v-list-item-content>{{
          $tc('purchase.purchaseOrder', 2)
        }}</v-list-item-content>
        <v-list-item-content class="font-weight-bold">{{
          meta.purchaseOrders || '-'
        }}</v-list-item-content
        ><v-list-item-action></v-list-item-action
      ></v-list-item>
      <ItemQuotationVolume
        v-if="itemQuotation.data"
        :item="itemQuotation.data"
      />
      <PurchaseOrderShippedVolume :order="order" />
      <PurchaseOrderMetricTons :order="order" />

      <ItemQuotationETA />
      <PurchaseOrderShowMetaBlockchain />
    </v-list>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex'
import ItemQuotationVolume from '@/views/item-quotation/components/ItemQuotationVolume'
import ItemQuotationETA from '@/views/item-quotation/components/ItemQuotationETA'
import PurchaseOrderShowMetaBlockchain from './PurchaseOrderShowMetaBlockchain'
import PurchaseOrderShippedVolume from './PurchaseOrderShippedVolume'
import PurchaseOrderMetricTons from './PurchaseOrderMetricTons'
export default {
  components: {
    ItemQuotationETA,
    ItemQuotationVolume,
    PurchaseOrderShowMetaBlockchain,
    PurchaseOrderShippedVolume,
    PurchaseOrderMetricTons
  },
  computed: {
    ...mapGetters({
      order: 'purchaseOrder/order',
      itemQuotation: 'itemQuotation/itemQuotation'
    }),
    meta() {
      return this.order.attributes.customAttributes
    }
  }
}
</script>
