import { render, staticRenderFns } from "./PurchaseOrderShowHeader.vue?vue&type=template&id=349cf121&"
import script from "./PurchaseOrderShowHeader.vue?vue&type=script&lang=js&"
export * from "./PurchaseOrderShowHeader.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports