<template>
  <v-row v-if="order">
    <v-col cols="12">
      <PurchaseOrderShowHeader />
      <PurchaseOrderShowMeta />
      <BillOfLadingDetails />
      <ItemQuotationDetails />
      <v-divider></v-divider>
      <OriginDestination />
    </v-col>
    <v-col cols="12">
      <PartyIndex
        :order-id="orderId"
        :forest-manager-ids="order.attributes.customAttributes.forestManagerIds"
      />
    </v-col>
    <v-col cols="12">
      <ItemResourceIndex
        v-if="itemQuotationId"
        :item-quotation-id="itemQuotationId"
    /></v-col>
    <v-col cols="12">
      <DocumentIndex :resource-id="orderId" resource-type="Order"
    /></v-col>
    <base-scroll-up-button />
  </v-row>
</template>

<script>
import PurchaseOrderShowHeader from './components/PurchaseOrderShowHeader'
import PurchaseOrderShowMeta from './components/PurchaseOrderShowMeta'
import ItemQuotationDetails from '@/views/item-quotation/components/ItemQuotationDetails'
import OriginDestination from '@/views/load/components/OriginDestination'
import ItemResourceIndex from '@/views/item-resource/components/ItemResourceIndex'
import DocumentIndex from '@/views/document/components/DocumentIndex'
import PartyIndex from '@/views/party/components/PartyIndex'
import BillOfLadingDetails from '@/views/purchase-order/components/BillOfLadingDetails'
import { mapActions } from 'vuex'
export default {
  components: {
    PurchaseOrderShowHeader,
    PurchaseOrderShowMeta,
    BillOfLadingDetails,
    ItemQuotationDetails,
    OriginDestination,
    ItemResourceIndex,
    DocumentIndex,
    PartyIndex
  },
  props: {
    orderId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      parentId: '',
      order: null,
      itemQuotationId: null
    }
  },

  created() {
    this.fetchData()
  },
  methods: {
    ...mapActions({
      fetchOrder: 'purchaseOrder/fetch',
      filterLoads: 'load/filter',
      filterItemQuotation: 'itemQuotation/filter',
      fetchItemQuotation: 'itemQuotation/fetch'
    }),
    async fetchData() {
      this.order = await this.fetchOrder(this.orderId)
      await this.$store.dispatch('load/filter', { orderIds: this.orderId })
      const response = await this.getItemQuotationId()
      await this.fetchItemQuotation(response)
      this.itemQuotationId = response
    },
    async getItemQuotationId() {
      const response = await this.filterItemQuotation({
        orderId: this.orderId
      })
      return response[0].id
    }
  }
}
</script>
