<template>
  <v-card flat tile
    ><v-card-title class="text-h6"
      ><v-icon left>mdi-receipt</v-icon>{{ $t('billOfLading.name') }}
      <v-spacer></v-spacer>
      <v-btn
        class="text-capitalize mx-2"
        depressed
        tile
        :to="{
          name: 'ocr-reader',
          params: { resourceId: order.id, module: 'purchase-order' }
        }"
        color="warning"
        ><v-icon left>mdi-upload</v-icon>{{ $t('actions.importPdf') }}</v-btn
      ></v-card-title
    >
    <v-divider></v-divider>
    <v-list v-if="order.attributes.customAttributes.billOfLadingNumber">
      <v-list-item>
        <v-list-item-content>{{
          $t('billOfLading.number')
        }}</v-list-item-content>

        <v-list-item-content class="font-weight-bold">{{
          order.attributes.customAttributes.billOfLadingNumber
        }}</v-list-item-content></v-list-item
      >
      <v-list-item>
        <v-list-item-content>{{
          $t('billOfLading.vessel')
        }}</v-list-item-content>

        <v-list-item-content class="font-weight-bold">{{
          order.attributes.customAttributes.vessel
        }}</v-list-item-content></v-list-item
      >
      <v-list-item>
        <v-list-item-content>{{ $t('billOfLading.eta') }}</v-list-item-content>

        <v-list-item-content class="font-weight-bold">{{
          order.attributes.customAttributes.estimatedDeliveryDate
            | moment('lll')
        }}</v-list-item-content></v-list-item
      >
      <template v-for="(item, i) in order.attributes.customAttributes.items">
        <v-list-item :key="i + 'name'">
          <v-list-item-content>{{
            $tc('inventory.item', 1)
          }}</v-list-item-content>
          <v-list-item-content class="font-weight-bold">{{
            item.name
          }}</v-list-item-content></v-list-item
        >
        <v-list-item :key="i + 'description'">
          <v-list-item-content>{{
            $t('billOfLading.description')
          }}</v-list-item-content>
          <v-list-item-content class="font-weight-bold">{{
            item.description
          }}</v-list-item-content></v-list-item
        >
        <v-list-item :key="i + 'weight'">
          <v-list-item-content>{{ $t('item.weight') }}</v-list-item-content>
          <v-list-item-content class="font-weight-bold"
            >{{ item.weight }} {{ item.weight_unit }}</v-list-item-content
          ></v-list-item
        >
        <v-list-item :key="i + 'volume'">
          <v-list-item-content>{{ $t('item.volume') }}</v-list-item-content>
          <v-list-item-content class="font-weight-bold"
            >{{ item.volume }} {{ item.volume_unit }}</v-list-item-content
          ></v-list-item
        >
      </template>
    </v-list>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  computed: {
    ...mapGetters({
      order: 'purchaseOrder/order'
    })
  }
}
</script>
