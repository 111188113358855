<template>
  <v-data-table
    dense
    :headers="headers"
    :items="itemResources"
    :items-per-page="25"
    :footer-props="{
      itemsPerPageOptions: [10, 25, 50, -1]
    }"
  >
    <template v-slot:item.dimensions="{ item }">
      {{
        item.attributes.dimensions.length &&
          `L ${item.attributes.dimensions.length}`
      }}
      {{
        item.attributes.dimensions.width &&
          `x W${item.attributes.dimensions.width}`
      }}

      {{
        item.attributes.dimensions.height &&
          `x T${item.attributes.dimensions.height}`
      }}
      {{
        (item.attributes.dimensions.length ||
          item.attributes.dimensions.width ||
          item.attributes.dimensions.height) &&
          item.attributes.dimensionsUnit
      }}
      {{
        item.attributes.dimensions.diameter &&
          `/ ⌀${item.attributes.dimensions.diameter} ${item.attributes.dimensionsUnit}`
      }}
    </template>

    <template v-slot:item.attributes.itemUnitsCount="{ item }">
      {{ item.attributes.itemUnitsCount }} ({{
        item.attributes.customAttributes.packQuantity || '1'
      }})
    </template>
    <template v-slot:item.attributes.volume="{ item }">
      {{
        item.attributes.volume &&
          `${item.attributes.volume} ${item.attributes.volumeUnit}`
      }}
    </template>
    <template v-slot:item.totalVolume="{ item }">
      {{
        item.attributes.volume *
          item.attributes.itemUnitsCount *
          (item.attributes.customAttributes.packQuantity || 1)
      }}
      {{ item.attributes.volume && item.attributes.volumeUnit }}
    </template>
    <template v-slot:item.attributes.status="{ item }">
      <v-chip
        v-if="item.attributes.status === 'pending'"
        small
        label
        color="success"
        @click.stop="updateItemStatus(item)"
      >
        <v-icon left small>mdi-check-circle-outline</v-icon>
        {{ $t('actions.confirm') }}
      </v-chip>
      <v-chip v-else small label light outlined text-color="grey lighten-1">
        {{ $t(`resourceStatus.${item.attributes.status}`) }}</v-chip
      >
    </template>

    <template v-slot:item.attributes.name="{ item }">
      {{ $t(`inventory.categories.${item.attributes.name}`) }}
    </template>

    <template v-slot:item.actions="{ item }">
      <BaseTableMenu
        module-name="itemResource"
        :item="item"
        :show-router="{
          name: 'item-resource-show',
          params: { itemResourceId: item.id }
        }"
        :edit-router="{
          name: 'item-resource-edit',
          params: {
            itemQuotationId: item.attributes.parentId,
            itemResourceId: item.id
          }
        }"
        qr
        @show-qr="$refs.dialog.createQrCode(item)"
      />
      <QrCodeGenerator ref="dialog" :url="qrUrl" />
    </template>
  </v-data-table>
</template>

<script>
import BaseTableMenu from '@/components/buttons/BaseTableMenu'
import QrCodeGenerator from '@/views/qr-code/components/QrCodeGenerator'
import { mapGetters } from 'vuex'
export default {
  name: 'ItemResourceIndexTable',
  components: {
    BaseTableMenu,
    QrCodeGenerator
  },
  computed: {
    ...mapGetters({
      itemResources: 'itemResource/itemResources'
    }),
    headers() {
      return this.createTableHeaders()
    },
    qrUrl: () => `${window.location.origin}/es/inventory/items/item-resource/`
  },
  methods: {
    updateItemStatus(selectedItem) {
      const item = { ...selectedItem }
      item.attributes.status = 'in_stock'
      this.$store.dispatch('itemResource/update', item)
    },
    createTableHeaders() {
      return [
        {
          text: this.$t('item.reference'),
          value: 'attributes.description',
          sortable: false
        },
        {
          text: this.$tc('item.name', 1),
          value: 'attributes.name',
          sortable: false
        },

        {
          text: this.$t('item.quality'),
          sortable: false,
          value: 'attributes.customAttributes.quality'
        },
        {
          text: this.$t('item.marking'),
          sortable: false,
          value: 'attributes.customAttributes.marking'
        },
        {
          text: 'L x W x T / ⌀',
          sortable: false,
          value: 'dimensions'
        },
        {
          text: this.$t('item.volume'),
          sortable: false,
          value: 'attributes.volume'
        },
        {
          text: this.$t('item.totalVolume'),
          align: 'start',
          value: 'totalVolume',
          sortable: false
        },
        {
          text: this.$tc('item.unit', 2),
          sortable: false,
          value: 'attributes.itemUnitsCount'
        },
        {
          text: this.$t('common.status'),
          sortable: false,
          value: 'attributes.status'
        },
        { text: '', value: 'data-table-expand' },
        {
          text: 'Actions',
          value: 'actions',
          sortable: false,
          align: 'end'
        }
      ]
    }
  }
}
</script>
